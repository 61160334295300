<!-- 个人中心---用户数据 -->
<template>
  <div class="userdata ht_accountAdmin">
    <usercenterhr class='usercenterhr' ></usercenterhr>
    <usercenterlt  class='usercenterlt' :imgupdata='imgupdata' :nav='5'></usercenterlt>
    <div class="rtinfo">
      <div class="group-data">
        <div class="lt">
          头像：
        </div>
        <div class="rt">
          <div class="img" :class='{imgs:headimg}'>
            <svg v-show='!headimg' class="icon"><use xlink:href="#iconzhanghao"></use></svg>
            <img v-show='headimg' :src="headimg">
          </div>
          <div class="upload" :class='{uploads:headimg}'>
            <button  @click='uploadshow=true'>上传头像</button>
          </div>
        </div>
      </div>
      <div class="group-data">
        <div class="lt">
          <span class="exp">*</span>
          用户名：
        </div>
        <div class="rt">
          <div class="ipt">
            <input type="text" v-model='username'>
          </div>
          <span v-if='error.username' class="error">{{error.username}}</span>
        </div>
      </div>
      <div class="group-data">
        <div class="lt">
          微信号：
        </div>
        <div class="rt">
          <div class="ipt">
            <input type="text" v-model='vxnumber'>
          </div>
        </div>
      </div>
      <div class="group-data">
        <div class="lt">
          <span class="exp">*</span>
          手机号：
        </div>
        <div class="rt">
          <div class="ipt">
            <input v-if='!phones' type="text" v-model='phonesipt'>
            <p v-if='phones'>{{phones | formatPhoneHide(1)}} <button @click="dialogVisible1 = true">修改</button></p>
          </div>
          <el-dialog
            :show-close='false'
            :visible.sync="dialogVisible1"
            width="470px"
            custom-class='phonepops'
            >
            <div  slot="title" class="tit">
              <p>修改手机号</p>
              <button  @click="dialogVisible1 = false"><svg><use xlink:href="#iconshanchu"></use></svg></button>
            </div>
            <div class="phonecnt">
              <div class="group-ph ysj">
                <!-- <input type="number" v-model='phones' disabled placeholder="原手机号"> -->
                <p class="ipts">{{phones | formatPhoneHide(1)}}</p>
                <!-- <span class="err">原手机号错误</span> -->
              </div>
              <div class="group-ph ph">
                <el-select v-model="value3">
                  <el-option
                    v-for="item in options3"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <input type="number" placeholder="新手机号" v-model='newphone'>
                <span class="err" v-if='phonerr.newphone'>{{phonerr.newphone}}</span>
              </div>
              <div class="group-ph yz">
                <input type="number" placeholder="输入验证码"  v-model='expcode'>
                <getcodebtn :initPhone='phones' :newPhone='newphone' :types='2' @exportErr='phoneError' @exportpass='phonepass' class='btn'></getcodebtn>
                <span class="err" v-if='phonerr.expcode'>{{phonerr.expcode}}</span>
              </div>
              <div class="group-ph">
                <button class="confirm" :disabled='disabled1' @click='confirm'>确认</button>
              </div>
            </div>
          </el-dialog>
          <span v-if='error.phonesipt' class="error">{{error.phonesipt}}</span>
        </div>
      </div>
      <div class="group-data">
        <div class="lt">
          邮箱：
        </div>
        <div class="rt">
          <div class="ipt">
            <input type="text" v-model='Email'>
          </div>
          <span v-if='error.Email' class="error">{{error.Email}}</span>
          
        </div>
      </div>
      <div class="group-data">
        <div class="lt">
          性别：
        </div>
        <div class="rt">
          <div class="rdo">
            <el-radio  v-model="radio" :label="0" >男</el-radio>
            <el-radio v-model="radio" :label="1" >女</el-radio>
          </div>
        </div>
      </div>
      <div class="group-data">
        <div class="lt">
          生日：
        </div>
        <div class="rt">
          <div class="birthdays">
            <el-date-picker
              v-model="birthday"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="group-data">
        <div class="lt">
          所在地区：
        </div>
        <div class="rt">
          <div class="addrs">
            <el-select  v-model="optionsObj.value" placeholder="省份" @change='change'>
             <el-option v-for="item,index in optionsObj.options" :key='index' :label='item.label' :value='item.value' ></el-option>
            </el-select>
            <el-select  v-model="optionsObj1.value" placeholder="城市" @change='change1'>
              <el-option  v-for="item,index in optionsObj1.options" :key='index' :label='item.label' :value='item.value' ></el-option>
            </el-select>
            <el-select  v-model="optionsObj2.value" placeholder="区县" @change='change2'>
              <el-option v-for="item,index in optionsObj2.options" :key='index' :label='item.label' :value='item.value' ></el-option>
            </el-select>
          </div>
          <span v-if='error.address' class="error">{{error.address}}</span>
        </div>
      </div>
     <!--  <div class="group-data">
        <div class="lt">
          所属行业：
        </div>
        <div class="rt">
          <div class="sltbtm">
            <button class="button" @click="dialogVisibleSlt">选择行业</button>
            <div class="trade">
              <button v-for='v,i in tradedata'  :key='i' ><span>{{v.name}}</span><svg :data-idx='i' @click.self='deltrade(v)'><use xlink:href="#iconshanchu"></use></svg></button>
            </div>
          </div>
          <el-dialog
            :show-close='false'
            :visible.sync="dialogVisible"
            width="470px"
            custom-class='trades'
            >
            <div  slot="title" class="tit">
              <p>行业选择</p>
              <button  @click="dialogVisible = false"><svg><use xlink:href="#iconshanchu"></use></svg></button>
            </div>
            <div class="cnt">
              <button @click='addtrade({name:item.TargetName,id:item.TargetID},index)' v-for='item,index in userDatasHy' >{{item.TargetName}}<svg v-show='tradeSlt[index]===true'><use xlink:href="#iconwancheng"></use></svg></button>
            </div>
          </el-dialog>
        </div>
      </div> -->
      <div class="group-data">
        <div class="lt">
        </div>
        <div class="rt">
          <div class="savedata">
            <button @click='saveuserdata' :disabled="disabled" :class='{choiceset:setUserinfo}'>保存</button>
          </div>
        </div>
      </div>
    </div>
    <my-upload field="img"
      @crop-success="cropSuccess"
      v-model="uploadshow"
      :noSquare='true'
      img-format="png"></my-upload>
  </div>
</template>
<script>
import 'babel-polyfill'; // es6 shim
import myUpload from 'vue-image-crop-upload';
import usercenterhr from '@/components/usercenter-hr/usercenter-hr.vue';//顶部hr
import usercenterlt from '@/components/usercenter-lt/usercenter-lt.vue';//左侧栏目
import {mixin,setOrGetCode,regPhone} from '../assets/js/common.js' //公用js
import getcodebtn from '@/components/getcodebtn/getcodebtn.vue';//获取验证码按钮组件-types属性切换接口
// import {regPhone,setOrGetCode,usermethod} from '../../assets/js/common.js' //公用js
import md5 from 'js-md5';
export default {
  name: 'userdata',
  mixins: [mixin],//混入
  components: {
    usercenterhr,
    usercenterlt,
    'my-upload': myUpload,
    getcodebtn
  },
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'个人信息-微微定',
      that:this,
      thatv:0,
      headimg:null,//头像是否存在
      disabled:false,
      disabled1:false,
      radio:0,//性别
      birthday:'',//生日
      optionsObj:{
        options:[
          {value:0,
          label:'省'},
        ],//选项卡---省
        value:0,//省
      },
      optionsObj1:{
        options:[
          {value:0,
          label:'市'},
        ],//选项卡---市
        value:0,//市
      },
      optionsObj2:{
        options:[
          {value:0,
          label:'区'},
        ],//选项卡---区
        value:0,//区
      },
      // value2: '',//下拉3-地址
      options3: [{
        value: '0086',
        label: '+86'
      }],
      value3: '0086',//下拉4-手机区号
      dialogVisible:false,//行业弹窗
      dialogVisible1:false,//手机号弹窗
      tradedata:[
      ],//选择行业
      // slt:false,//选择行业
      // slt1:false,//选择行业
      // slt2:false,//选择行业
      // slt3:false,//选择行业
      // slt4:false,//选择行业
      // slt5:false,//选择行业
      // slt6:false,//选择行业
      // slt7:false,//选择行业
      tradeSlt:[],//选择行业
      uploadshow: false,//显示/隐藏头像上传插件
      phones:'',//手机号存在就显示修改按钮，不存在就显示输入框
      // phones:'18752360925',//手机号存在就显示修改按钮，不存在就显示输入框
      phonesipt:'',//输入手机号
      username:'',//用户名
      vxnumber:'',//微信号
      Email:'',//邮箱
      newphone:'',//新手机号
      expcode:'',//验证码
      phonerr:{
        newphone:'',
        expcode:'',
      },//验证错误信息，通过复制显示信息
      addressData:{
        addresId:0,//省，
        addresId1:0,//市，
        addresId2:0,//区，
      },
      error:{
        username:'',
        phonesipt:'',
        Email:'',
        address:''
      },
      userDatas:null,//回显信息
      userDatasHy:null,//回显行业信息
      phonereg:false,//验证手机号
      codesreg:false,//验证验证码
      imgupdata:0,//头像更新
      setUserinfo:false
    }
  },
  props: {
    msg: String
  },
  methods:{
    regEmail(v) {// 邮箱验证
      var TEL_REGEXP = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
      return TEL_REGEXP.test(v);
    },
    saveuserdata(){
      // 保存用户信息
      var _flag = true,_address = true;
      this.error.username = ''
      this.error.phonesipt = ''
      this.error.Email = ''
      this.error.address = ''
      if(!this.username){
        _flag = false
        this.error.username = '请填写用户名'
      }
      if(!this.phonesipt){
        _flag = false
        this.error.phonesipt = '请填写手机号'
      }
      if(this.Email&&!this.regEmail(this.Email)){
        _flag = false
        this.error.Email = '邮箱格式错误'
      }
      // addressData:{
      //   addresId:0,//省，
      //   addresId1:0,//市，
      //   addresId2:0,//区，
      // },
      if(this.addressData.addresId>0||this.addressData.addresId1>0||this.addressData.addresId2>0){
        for(let i in this.addressData){
          if(this.addressData[i]===0){
            _flag = false
            this.error.address = '请填写完整地址'
          }
        }
      }
      if(_flag){
        var _parms = {
          // CustomerId:this.$store.state.user&&this.$store.state.user.CustomerID?this.$store.state.user.CustomerID:'',
          token:this._token({method:'get'}),
          WechatNumber:this.vxnumber,
          ContactName:this.username,
          Email:this.Email,
          Gender:this.radio,
          BirthDay:this.birthday?new Date(this.birthday).toLocaleDateString():'',
          // BirthDay:this.birthday,
          ProvinceID:this.addressData.addresId,
          CityID:this.addressData.addresId1,
          AreaID:this.addressData.addresId2,
          // TargetID:this.birthday,
        }
        // console.log(_parms)
        this.disabled = true;
        this._axios({then:()=>{
          // this.axios.post(process.env.VUE_APP_URL+'UserCenter/SaveCustomerData?CustomerId='+(this.$store.state.user&&this.$store.state.user.CustomerID?this.$store.state.user.CustomerID:'')+'&WechatNumber='+this.vxnumber+'&ContactName='+this.username+'&Email='+this.Email+'&Gender='+this.radio+'&BirthDay='+this.birthday+'&ProvinceID='+this.addressData.addresId+'&CityID='+this.addressData.addresId1+'&AreaID='+this.addressData.addresId2,this.keys())
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/SaveCustomerData',this.keys(_parms))
          .then(res => {
              if(res.data.Success){
                this.disabled =false
                this.$Message({
                    message: '保存成功!',
                    type: 'success',
                    duration:1500,
                    // offset:100
                });
                this.$store.dispatch("cmtSetUser",res.data.Data.CustomersDto);//改状态
                this._usermet({method:'set',user:res.data.Data.CustomersDto});//存数据
              }else{
                this.disabled =false
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
              console.log('系统异常')
            })
        },exp:true,catch:()=>{
              this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
              // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
    },
    confirm(){
      // 修改手机号确认按钮
      this.phonereg = true
      this.phonerr.newphone  = ''
      this.phonerr.expcode  = ''
      if(!this.expcode){
        this.phonerr.expcode = '请输入验证码'
      }
      if(!this.newphone){
        this.phonerr.newphone = '请输入手机号'
      }
      if(!regPhone(this.newphone)){
        this.phonereg = false
        this.phonerr.newphone = '请输入正确的手机号'
      }
      if(this.newphone==this.phones){
        this.phonereg = false
        this.phonerr.newphone = '不能绑定原手机'
      }
      if(setOrGetCode({method:'get'})&&md5(this.expcode).toLocaleUpperCase()==setOrGetCode({method:'get'})){
        this.codesreg = true
      }else{
        this.phonerr.expcode = '验证码错误'
        this.codesreg = false
        // this.codes = ''
      }
      if(this.phonereg&&this.codesreg){
        // 验证码通过
        this.disabled1 = true;
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/UpdatePhone?oldPhone='+this.phones+'&newPhone='+this.newphone,this.keys({token:this._token({method:'get'})}))
          .then(res => {
              if(res.data.Success){
                this.disabled1 = false;
                this.dialogVisible1 = false;
                this.initUserData((item)=>{
                  this.initAddress(item)
                })
                this.$Message({
                    message: '修改成功!',
                    type: 'success',
                    duration:1500,
                    // offset:100
                });
              }else{
                this.disabled1 = false;
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
              console.log('系统异常')
            })
        },exp:true,catch:()=>{
              this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
              // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
    },
    phoneError(v){
      // 手机号错误
      // this.errorData.phonetxt = v
      this.phonereg = false
      // this.phone=''
      this.phonerr.newphone = v
    },
    phonepass(){
      this.phonerr.newphone  = ''
      // 手机号通过
      // this.phonetxt = '请输入手机号码'
      this.phonereg = true
    },
    dataURLtoBlob(dataurl){
      // 转换base64为bold
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    cropSuccess(imgDataUrl, field){
      this.headimg =imgDataUrl
      this.thatv =1
      var _bold = this.dataURLtoBlob(imgDataUrl)
      var _parms = {
        FileBase:imgDataUrl,
        token:this._token({method:'get'})
      }
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/SetUserHeadUrl',this.keys(_parms))
        .then(res => {
            if(res.data.Success){
              this.imgupdata++
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
            console.log('系统异常')
          })
      },exp:true,catch:()=>{
            this.$router.push({path:'/accountlogin',query:{redirect:'/index.html'}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    dialogVisibleSlt(){
      this.dialogVisible = true
      // console.log(this.tradedata)
      this.tradeSlt = [];
      for(let i in this.userDatasHy){
        for(let j in this.tradedata){
          if(this.tradedata[j].id===this.userDatasHy[i].TargetID){
            this.tradeSlt[i]=true
          }
        }
      }
    },
    addtrade(v,idx){
      // 添加行业
      var flag = true;
      for(let i in this.tradedata){
        if(this.tradedata[i].id==v.id){
          flag = false
        }
      }
      if(flag){
        this.tradeSlt[idx] = true
        this.tradedata.push(v)
      }
    },
    deltrade(v){
      // console.log($(e.target).data('idx'))
      // 删除行业
      var flag = false,index = 0;
      for(let i in this.tradedata){
        if(this.tradedata[i].id==v.id){
          flag = true
          index = i
        }
      }
      if(flag){
        this.tradedata.splice(index,1)
      }
    },
    change(v,fn){
      // 地址栏选择--省
      this.$set(this,'optionsObj1',{
        options:[
          {value:0,
          label:'市'},
        ],//选项卡---配送方式
        value:0,//选项卡配送方式值
      })
      this.addressData.addresId1 = 0;
      this.$set(this,'optionsObj2',{
        options:[
          {value:0,
          label:'区'},
        ],//选项卡---配送方式
        value:0,//选项卡配送方式值
      })
      this.addressData.addresId2 = 0;
      this.addressData.addresId = v;
      if(v!=0){
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainCityList?ProvinceId='+v,this.keys())
          .then(res => {
              if(res.data.Success){
                var _data = [{value:0,
                    label:'市'}];
                for(let i in res.data.Data){
                  var _obj = {};
                  _obj.value = res.data.Data[i].CityID
                  _obj.label = res.data.Data[i].CityName
                  _data.push(_obj)
                }
                this.$set(this.optionsObj1,'options',_data)
                if(fn){
                  fn();
                }
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
        }})
      }
    },
    change1(v,fn){
      // 地址栏选择--市
      this.addressData.addresId1 = v
      this.$set(this,'optionsObj2',{
        options:[
          {value:0,
          label:'区'},
        ],//选项卡---配送方式
        value:0,//选项卡配送方式值
      })
      this.addressData.addresId2 = 0
      if(v!=0){
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainAreaList?ProvinceID='+this.addressData.addresId+'&CityId='+v,this.keys())
          .then(res => {
              if(res.data.Success){
                var _data = [{value:0,
                    label:'区'}];
                for(let i in res.data.Data){
                  var _obj = {};
                  _obj.value = res.data.Data[i].AreaID
                  _obj.label = res.data.Data[i].AreaName
                  _data.push(_obj)
                }
                this.$set(this.optionsObj2,'options',_data)
                if(fn){
                  fn();
                }
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
        }})
      }
    },
    change2(v,fn){
      // 地址栏选择--区
      this.addressData.addresId2 = v;
      if(fn){
        fn();
      }
    },
    initUserData(fn){
      // 初始化用户数据
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainUserInfo',this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              this.userDatas = res.data.Data.customer
              this.userDatasHy = res.data.Data.targetList
              if(fn){
                fn(this.userDatas)
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
            console.log('系统异常')
          })
      },exp:true,catch:()=>{
            this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    initAddress(item){
      // 初始化地址
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainProvinceList',this.keys())
        .then(res => {
            if(res.data.Success){
              var _data = [{value:0,
                  label:'省'}];
              for(let i in res.data.Data){
                var _obj = {};
                _obj.value = res.data.Data[i].ProvinceID
                _obj.label = res.data.Data[i].ProvinceName
                _data.push(_obj)
              }
              this.$set(this.optionsObj,'options',_data)
              this.addressData.addresId =item.ProvinceID
              this.addressData.addresId1 =item.CityID
              this.addressData.addresId2 =item.AreaID
               for(let i in this.optionsObj.options){
                  if(this.optionsObj.options[i].value==item.ProvinceID){
                    this.optionsObj.value = this.optionsObj.options[i].value
                    this.change(this.optionsObj.value,()=>{
                      for(let i in this.optionsObj1.options){
                        if(this.optionsObj1.options[i].value==item.CityID){
                          this.optionsObj1.value = this.optionsObj1.options[i].value
                          this.change1(this.optionsObj1.value,()=>{
                            for(let i in this.optionsObj2.options){
                              if(this.optionsObj2.options[i].value==item.AreaID){
                                this.optionsObj2.value = this.optionsObj2.options[i].value
                                this.change2(this.optionsObj2.value)
                              }
                            }
                          })
                        }
                      }
                    })
                  }
                }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
    },
    localReset(){
      var _this  = this;
      // 隐藏弹窗
      $($('.ht_accountAdmin .vue-image-crop-upload')[0]).bind('click',function(e){
        if($(e.target).attr('class')&&$(e.target).attr('class')==='vue-image-crop-upload'){
          _this.uploadshow = !_this.uploadshow;
        }
      })
      // 修改头像插件添加关闭按钮
      $('.ht_accountAdmin .vue-image-crop-upload .vicp-wrap').prepend($('<svg  class="icon close-svg"><use xlink:href="#iconguanbi"></use></svg>'))
      // 隐藏弹窗
      $($('.ht_accountAdmin .vue-image-crop-upload .vicp-wrap .close-svg')[0]).bind('click',function(e){
        _this.uploadshow = !_this.uploadshow;
      })
      this.initUserData((item)=>{
        this.initAddress(item)
      })
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.localReset()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  },
  watch:{
    userDatas(n,o){
      if(n){
        // 回显信息
        // console.log(n)
        this.phones=n.Mobile
        this.phonesipt=n.Mobile
        this.username=n.ContactName
        this.vxnumber = n.WechatNumber
        this.Email = n.Email
        this.radio = n.Gender
        this.birthday = n.BirthDay
        this.headimg = n.WxDisplayUrl
        // this.addressData.addresId =n.ProvinceID
        // this.addressData.addresId1 =n.CityID
        // this.addressData.addresId2 =n.AreaID
        //  for(let i in this.optionsObj.options){
        //     if(this.optionsObj.options[i].value==this.addressData.addresId){
        //       this.optionsObj.value = this.optionsObj.options[i].value
        //       this.change(this.optionsObj.value,()=>{
        //         for(let i in this.optionsObj1.options){
        //           if(this.optionsObj1.options[i].value==this.addressData.addresId1){
        //             this.optionsObj1.value = this.optionsObj1.options[i].value
        //             this.change1(this.optionsObj1.value,()=>{
        //               for(let i in this.optionsObj2.options){
        //                 if(this.optionsObj2.options[i].value==this.addressData.addresId2){
        //                   this.optionsObj2.value = this.optionsObj2.options[i].value
        //                   this.change2(this.optionsObj2.value)
        //                 }
        //               }
        //             })
        //           }
        //         }
        //       })
        //     }
        //   }
      }
    },
    userDatasHy(n,o){
    },
    headimg(n,o){
      if(n!=this.userDatas.WxDisplayUrl){
        this.setUserinfo = true
      }else{
        this.setUserinfo = false
      }
    },
    username(n,o){
      if(n!=this.userDatas.ContactName){
        this.setUserinfo = true
      }else{
        this.setUserinfo = false
      }
    },
    vxnumber(n,o){
      if(n!=this.userDatas.WechatNumber){
        this.setUserinfo = true
      }else{
        this.setUserinfo = false
      }
    },
    phones(n,o){
      if(n!=this.userDatas.Mobile){
        this.setUserinfo = true
      }else{
        this.setUserinfo = false
      }
    },
    Email(n,o){
      if(n!=this.userDatas.Email){
        this.setUserinfo = true
      }else{
        this.setUserinfo = false
      }
    },
    radio(n,o){
      if(n!=this.userDatas.Gender){
        this.setUserinfo = true
      }else{
        this.setUserinfo = false
      }
    },
    birthday(n,o){
      if(n==null){
        n=''
      }
      if(n!=this.userDatas.BirthDay){
        this.setUserinfo = true
      }else{
        this.setUserinfo = false
      }
    },
    'addressData.addresId'(n,o){
      if(this.addressData.addresId!=this.userDatas.ProvinceID){
        this.setUserinfo = true
      }else{
        this.setUserinfo = false
      }
      if(this.addressData.addresId1!=this.userDatas.CityID){
        this.setUserinfo = true
      }else{
        this.setUserinfo = false
      }
      if(this.addressData.addresId2!=this.userDatas.AreaID){
        this.setUserinfo = true
      }else{
        this.setUserinfo = false
      }
    },
    'addressData.addresId1'(n,o){
      if(this.addressData.addresId!=this.userDatas.ProvinceID){
        this.setUserinfo = true
      }else{
        this.setUserinfo = false
      }
      if(this.addressData.addresId1!=this.userDatas.CityID){
        this.setUserinfo = true
      }else{
        this.setUserinfo = false
      }
      if(this.addressData.addresId2!=this.userDatas.AreaID){
        this.setUserinfo = true
      }else{
        this.setUserinfo = false
      }
    },
    'addressData.addresId2'(n,o){
      if(this.addressData.addresId!=this.userDatas.ProvinceID){
        this.setUserinfo = true
      }else{
        this.setUserinfo = false
      }
      if(this.addressData.addresId1!=this.userDatas.CityID){
        this.setUserinfo = true
      }else{
        this.setUserinfo = false
      }
      if(this.addressData.addresId2!=this.userDatas.AreaID){
        this.setUserinfo = true
      }else{
        this.setUserinfo = false
      }
    }
  },
  filters:{
    imgsrc(v,that,thatv){
      if(thatv===0){
        return that._src + v
      }else {
        return v
      }
    }
  }
}
</script>
<style lang="scss">
// 修改头像插件初始化样式
  .ht_accountAdmin {
    .vue-image-crop-upload {
      z-index: 99999;
      .vicp-wrap {
        width: 425px;
        border-radius:5px;
        .vicp-step1 {
          .vicp-drop-area {
            height: 235px;
            .vicp-icon1 {
              margin-top:40px;
            }
          }
          .vicp-operate {
            // width: 90px;display: block;height: 40px;line-height: 40px;text-align: center;    font-size: 14px;border-radius: 5px;color: #5e5e5e;background: #eeeeee;
            a {
              // width:90px;height: 40px;margin-left: 0px;    line-height: 40px;
              color: #5e5e5e;
            }
          }
        }
        .vicp-step2 {
          .vicp-operate {
            a {
              color: #5e5e5e;
            }
            .vicp-operate-btn {
              color:#f74762;
            }
          }
        }
        .vicp-close {
          display: none;
        }
        .close-svg {
          position: absolute;top:0px;right:-32px;width:25px;height: 25px;fill: #fff;cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }

      }
    }
  }
</style>
<style  lang="scss" scoped>
.userdata {
  width: 1200px;margin:0 auto;zoom:1;padding-bottom:20px;
  .usercenterhr {
    width: 1200px;margin:0 auto;margin-top:20px;margin-bottom:10px;position: relative;z-index: 9;
  }
  .usercenterlt {
    float:left;width: 180px;margin-top:-10px;
  }
  .rtinfo {
    width: 1010px;float:right;background: #fff;margin-top:-10px;padding-top:75px;
    .group-data {
      display: flex;justify-content: flex-start;margin-bottom:40px;
      .lt {
        width: 110px;text-align: right;font-size: 14px;color:#333;
        .exp {
          color:#f44e5e;vertical-align: -2px;
        }
      }
      .rt {
        display: flex;justify-content: flex-start;position: relative;
        .img {
          width:94px;height: 94px;border-radius: 50%;border:2px solid #d8d8d8;background: #f3f3f3;display: flex;justify-content: center;align-items: center;margin-top:-38px;margin-left:30px;box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.2);
          img {
            width: 94px;height: 94px;border-radius: 50%;
          }
          .icon {
            fill: #d8d8d8;width: 60px;height: 60px;
          }
        }
        .imgs {
          border-color:#f44e5e;
        }
        .upload {
          margin-left:50px;
          button {
            width: 94px;height: 34px;margin-top:-20px;border-radius: 5px;font-size: 14px;color:#333333;
            &:hover {
              opacity: 0.8;
            }
          }
        }
        .uploads {
          button {
            background: #f44e5e;color:#fff;
          }
        }
        .ipt {
          margin-top:-12px;margin-left:10px;
          input {
            border:1px solid #dcdcdc;width: 308px;height: 40px;border-radius: 3px;font-size: 14px;padding-left:10px;
          }
          p {
            height: 40px;line-height: 44px;
            button {
              background: none;margin-left:50px;vertical-align: 1px;color:#f44e55;
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
        .rdo {
          margin-left:10px;
          /deep/.is-checked {
            .el-radio__inner {
              background: #f44e5e;border-color: #f44e5e;
            }
            .el-radio__label {
              color:#f44e5e;
            }
          }
        }
        .birthdays {
          margin-top:-10px;margin-left:10px;
        }
        .addrs {
          margin-top:-10px;margin-left:10px;
          .el-select {
            margin-right: 10px;
          }
        }
        .sltbtm {
          margin-left:10px;margin-top:-10px;
          .button {
            width: 112px;height: 40px;line-height: 40px;font-size: 14px;border-radius: 5px;background: #e9e9e9;
            &:hover {
              background: #f44e55;color:#fff;
            }
          }
          .trade {
            margin-top:20px;zoom:1;
            button {
              width: 100px;height: 40px;border:1px solid #db312f;background: #d49898;font-size: 14px;color:#000;display: flex;justify-content: space-between;align-items: center;border-radius: 3px;margin-right: 20px;margin-bottom:20px;float:left;
              svg {
                margin-right: 10px;width: 16px;height: 16px;fill: #5f5f5f;
                &:hover {
                  opacity: 0.8;
                }
              }
              span {
                margin-left:10px;
              }
              &:nth-child(2) {
                border-color:#db8a4d;background: #e2c3ae;
              }
              &:nth-child(3) {
                border-color:#4987d4;background: #98b4d7;
              }
              &:nth-child(4) {
                border-color:#4aa0dc;background: #a1bdd4;
              }
              &:nth-child(5) {
                border-color:#d89846;background: #d7b890;
              }
              &:nth-child(6) {
                border-color:#1160c9;background: #89aad7;
              }
              &:nth-child(7) {
                border-color:#cd6120;background: #d8a687;
              }
              &:nth-child(8) {
                border-color:#a09f9f;background: #cbc8c8;
              }
            }
            &:after {
              content: '';display: block;clear:both;
            }
          }
        }
        .savedata {
          margin-left:10px;margin-top:-10px;
          button {
            width: 142px;height: 48px;border-radius: 5px;color:#7a7a7a;font-weight: bold;font-size: 14px;
            &:hover {
              background: #f44e55;color:#fff;
            }
          }
          .choiceset {
            background: #f44e55;color:#fff;
          }
        }
        /deep/ .trades {
          border-radius:10px;overflow: hidden;
          .el-dialog__header {
            padding:0px;
            .tit {
              display: flex;justify-content: space-between;height: 40px;background: #f3f3f3;align-items: center;
              p {
                margin-left:10px;height: 40px;line-height: 40px;color:#898989;
              }
              button {
                margin-right: 10px;background: none;
                svg {
                  width: 16px;height: 16px;fill: #8f8f8f;
                }
              }
            }
          }
          .el-dialog__body {
            padding:0px;
            .cnt {
              padding:0 64px;padding-top:30px;
              button {
                width: 158px;height: 60px;margin-right: 26px;margin-bottom:30px;border-radius: 5px;font-size: 20px;color:#fff;position: relative;background: #f16666;
                svg {
                  position: absolute;width: 30px;height: 30px;fill: #fff;bottom:5px;right:5px;
                }
                &:nth-child(2n+2){
                  margin-right: 0px;
                }
                &:hover {
                  opacity: 0.8;
                }
                &:nth-child(2){
                  background: #ff9d55;
                }
                &:nth-child(3){
                  background: #55a1ff;
                }
                &:nth-child(4){
                  background: #55b9ff;
                }
                &:nth-child(5){
                  background: #116ae4;
                }
                &:nth-child(6){
                  background: #e97026;
                }
                &:nth-child(7){
                  background: #ff9d55;
                }
                &:nth-child(8){
                  background: #55b9ff;
                }
                &:last-child {
                  background: #d4d4d4;
                }
              }
            }
          }
        }
        /deep/ .phonepops {
          border-radius:10px;overflow: hidden;
          .el-dialog__header {
            padding:0px;
            .tit {
              display: flex;justify-content: space-between;height: 40px;background: #f3f3f3;align-items: center;
              p {
                margin-left:10px;height: 40px;line-height: 40px;color:#898989;
              }
              button {
                margin-right: 10px;background: none;
                svg {
                  width: 16px;height: 16px;fill: #8f8f8f;
                }
              }
            }
          }
          .el-dialog__body {
            padding:0px;
            .phonecnt {
              width: 340px;margin:0 auto;margin-top:30px;margin-bottom:5px;
              .group-ph {
                display: flex;justify-content: flex-start;margin-bottom:25px;position: relative;
                input {
                  width: 320px;height: 46px;border:1px solid #e9e9e9;border-radius: 5px;padding-left:20px;
                  &::placeholder {
                    font-size: 14px;color:#cecece;
                  }
                }
                .getcodebtn {
                  margin-left:15px;
                }
                .confirm {
                  width: 340px;height: 46px;border-radius: 5px;color:#939393;font-size: 16px;
                  &:hover {
                    background: #f44e55;color:#fff;
                  }
                }
                .err {
                  position: absolute;top:48px;font-size: 12px;left:0px;color:#f44e55;
                }
              }
              .yz {
                input {
                  width: 160px;
                }
              }
              .ph {
                input {
                  &:nth-child(1) {
                    width: 75px;height: 48px;border-right: 0px;border-top-right-radius: 0px;border-bottom-right-radius: 0px;
                  }
                  &:nth-child(2) {
                    border-left:0px;border-top-left-radius: 0px;border-bottom-left-radius: 0px;
                  }
                }
              }
              .ysj {
                .ipts {
                  width: 340px;height: 46px;border:1px solid #e9e9e9;border-radius: 5px;padding-left:20px;line-height: 46px;
                }
              }
            }
          }
        }
        .error {
          position: absolute;color:#f44e5e;font-size: 12px;left:10px;top:32px;
        }
      }
      &:nth-child(1) {
        margin-bottom:40px;
      }
    }
  }
  &:after {
    content: "";display: block;clear:both;
  }
}
</style>
